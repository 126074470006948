<template>
  <div class="custom-profile-main-box"
       :class="[{'is-mobile': isMobile}]"
       v-if="profile && Object.keys(profile).length > 0">
    <div class="profile-info-box">
      <div class="profile-box">
        <template v-if="profile.avatar">
          <div @click="imagePreviewStatus = true"
               class="profile-avatar-box">
            <lazy-image class="profile-avatar cursor-pointer"
                        :class="[typeof profile.avatar === 'object' ? `size-${profile.avatar.size}` : '']"
                        :src="typeof profile.avatar === 'object' ? profile.avatar.value : profile.avatar"
                        :alt="profile.name"/>
          </div>

          <image-preview v-if="imagePreviewStatus"
                         :image="{src: typeof profile.avatar === 'object' ? profile.avatar.value : profile.avatar, alt: profile.name}"
                         @closed="imagePreviewStatus = false"/>
        </template>

        <div v-if="profile.name" class="profile-name w-auto">
          {{ profile.name }}
        </div>

        <div v-if="getImportantActions.length > 0" class="important-actions-box">
          <ul class="important-actions-list">
            <template v-for="(action, action_index) in getImportantActions">
              <profile-action :action="action" :key="action_index" :selected-action="selectedAction" @eventClick="(event) => handleClickAction(event, action)" />
            </template>
          </ul>
        </div>

        <div v-if="profile.details"
                v-for="(detail, detail_index) in profile.details"
                :key="detail_index">

          <div class="profile-details w-auto mx-auto">

            <vs-icon v-if="detail.icon"
                     class="w-auto inline-block mr-1"
                     :icon="detail.icon"
                     :icon-pack="detail.iconPack"/>

            <div class="w-auto inline-block">
              {{ `${detail.key} ${detail.key ? ':' : ''} ${detail.value}` }}
            </div>
          </div>
        </div>

        <div class="important-data-list">
          <div class="important-data-item"
                  :class="important_data.class || ''"
                  v-for="(important_data, important_data_index) in profile.importantData"
                  :key="important_data_index">
            <div class="key w-full useral-font-weight-medium">
              {{ important_data.key }}
            </div>
              <div v-if="important_data.hasOwnProperty('type') && important_data.type === 'price'"
                   class="value w-full mt-1"
                   :class="important_data.valueClass">
                {{ addComma(important_data.value) }}
              </div>

              <div v-else
                   class="value w-full mt-1"
                   :class="important_data.valueClass">
                {{ important_data.value }}
              </div>
          </div>
        </div>
      </div>

      <div class="actions-list-box">
        <ul class="actions-list">
          <template v-for="(action, action_index) in actionsList.filter(elm => elm.type !== 'important')">
            <profile-action :action="action" :key="action_index" :selected-action="selectedAction" @eventClick="(event) => handleClickAction(event, action)" />
          </template>
        </ul>
      </div>

      <div class="description" v-if="profile.description">
        <p>{{ profile.description }}</p>
      </div>
    </div>
    <div v-if="!isMobile" class="profile-content-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/lazyImage/lazyImage'
import ImagePreview from '@/components/imagePreview/imagePreview'
import {addComma, checkUserPermissions} from "../../assets/js/functions";
import CustomIcon from "../customIcon/customIcon";
import historyStates from "../../mixins/historyStates";
import ProfileAction from "./profileAction.vue";
import {mapGetters} from "vuex";

export default {
  name: 'profileMainBox',
  components: {
    ProfileAction,
    CustomIcon,
    ImagePreview,
    LazyImage
  },
  mixins: [historyStates],
  props: {
    profile: {
      type: Object,
      default: () => {
        return {}
      }
    },
    actionsList: {
      type: Array,
      default() {
        return []
      }
    },
    defaultAction: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      imagePreviewStatus: false,
      selectedAction: '',
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    getImportantActions () {
      return this.actionsList.filter(elm => elm.type === 'important')
    }
  },
  created() {
    if (!this.isMobile) {
      this.selectedAction = this.defaultAction
    }
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    addComma(val) {
      return addComma(val)
    },
    handleClickAction (event, action) {
      this.selectedAction = action.event
      this.$emit(event)
    }
  },
  watch: {
    defaultAction: {
      handler (val) {
        if (val !== this.selectedAction) {
          this.selectedAction = val
        }
      }
    }
  }
}
</script>

<style lang="scss">
.vx-navbar-wrapper.content-navbar.bottom-border-radius-0.profile-page-navbar {
  border-bottom: 1px solid #f5f5f5 !important;

  .vs-navbar {
    box-shadow: none !important;
  }

  .bottom-shadow {
    display: none;
  }
}

.custom-profile-main-box {
  $mainSpace: 15px;

  display: flex;
  height: 100%;
  padding: 0;

  .vx-card__body {
    padding: 0 !important;
  }

  .profile-info-box {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;

    &::-webkit-scrollbar {
      display: block;
      background: rgba(206, 206, 206, 0.50);
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      border-radius: .5rem;
    }

    * {
      direction: ltr;
    }

    @media print {
      display: none;
    }

    .profile-box {
      margin: $mainSpace;
      margin-top: 0;

      .profile-avatar-box {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        .profile-avatar {
          object-fit: cover;
          aspect-ratio: 1 / 1;
          border-radius: 25px;
          max-width: 225px;
          height: 225px;
        }
      }

      .profile-name {
        font-size: 15px;
        font-weight: 700;
        margin: 15px auto 15px;
        text-align: center;
      }

      .important-actions-box {
        padding: $mainSpace 0;
        border-bottom: 1px solid #f5f5f5;

        .important-actions-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin: auto;
        }
      }

      .profile-details {
        text-align: center;

        &:first-of-type {
          padding-top: $mainSpace;
        }

        &:last-of-type {
          padding-bottom: $mainSpace;
          border-bottom: 1px solid #f5f5f5;
        }
      }

      .important-data-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
        border-bottom: 1px solid #f5f5f5;

        .important-data-item {
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: fit-content !important;
          padding: $mainSpace;

          .key {
            font-size: 12px;
            color: #999999;
            margin-top: 3px;
          }

          .value {
            font-size: 15px;
            font-weight: 600;

            &.rtl {
              direction: rtl;
            }

            &.ltr {
              direction: ltr;
            }
          }
        }
      }
    }

    .actions-list-box {
      margin: auto;

      .actions-list {

        a {


          &:last-child {

            li {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .description {
      padding: $mainSpace;
      margin: auto;
      margin-top: $mainSpace;
      line-height: 25px;
      background: #ffffff;
      border-top: 1px solid #f5f5f5;
      border-radius: .5rem;
      box-shadow: 0px 4px 10px -3px cecece;

      p {
        text-align: justify;
      }
    }
  }

  .profile-content-box {
    padding: $mainSpace;
    flex: 1;
    border-left: 1px solid #f5f5f5;
    overflow: auto;

    &::-webkit-scrollbar {
      display: block;
      background: rgba(206, 206, 206, 0.50);
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      border-radius: .5rem;
    }
  }

  &.is-mobile {
    .profile-info-box {
      max-width: unset;
      min-width: unset;
      width: unset;
      flex: 1;

      .profile-box {
        .profile-avatar-box {
          .profile-avatar {
            width: 50%;
            height: auto;

            &.size-small {
              width: 33%;
              height: auto;
            }

            &.size-medium {
              width: 50%;
              height: auto;
            }

            &.size-big {
              width: 80%;
              height: auto;
            }
          }
        }
      }
    }

    .profile-content-box {
      border-left: none;
    }
  }
}
</style>
