<template>
  <li class="profile-action" :class="[action.class, {'active': checkActionIsActive}]" @click="handleClickAction($event, action)">
    <template v-if="action.iconPack === 'useral'">
      <div class="action-icon">
        <custom-icon :icon="action.icon"
                     height="17px"
                     width="17px"
                     :color-code="action.colorCode ? action.colorCode : checkActionIsActive ? '#000000' : '#888888'"
                     :color="action.color ? action.color : ''"/>
      </div>
    </template>
    <template v-else>
      <vs-icon class="action-icon"
               :class="[action.color ? `text-${action.color}`:'']"
               :icon="action.icon"
               :icon-pack="action.iconPack"/>
    </template>

    <div class="action-label w-auto"
         :class="[action.color ? `text-${action.color}`:'']">
      {{ $t(action.i18n) || action.header }}
    </div>

    <vs-spacer/>

    <div v-if="action.badge"
         class="action-badge-box">
      <div class="action-badge"
           :class="[action.badge.type !== 'icon' ? `badge-${action.badge.color || 'primary'}`: '']">

        <vs-icon v-if="action.badge.type === 'icon'"
                 :class="[action.color ? `text-${action.color}`:'', {'mr-2': action.hasOwnProperty('extra')}]"
                 :icon="action.icon"
                 :icon-pack="action.iconPack"/>

        <div v-else>
          {{ action.badge.data }}
        </div>

      </div>
    </div>

    <div v-if="action.extra" class="action-extra-content">
        <span :class="[action.extra.hasOwnProperty('classes') ? action.extra.classes : action.extra.value >= 0 ? 'text-success' : 'text-danger']"
              v-if="typeof action.extra === 'object' && action.extra.hasOwnProperty('type') && action.extra.type === 'price'">
<!--          <template v-if="action.extra.value">-->
          {{ `${addComma(Math.abs(action.extra.value || 0) || 0, true)} ${ $locale.currency()}` }}
<!--          </template>-->
        </span>
      <span :class="[typeof action.extra === 'object' && action.extra.classes]"
            v-else>
          {{ typeof action.extra === 'object' ? action.extra.value : action.extra }}
        </span>
    </div>
  </li>
</template>

<script>
import {addComma} from "../../assets/js/functions";
import CustomIcon from "../customIcon/customIcon.vue";
import {mapGetters} from "vuex";

export default {
  name: "profileActionItem",
  components: {CustomIcon},
  props: {
    action: {
      type: Object,
      default: {},
      required: true
    },
    selectedAction: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    checkActionIsActive () {
      if (this.action.hasOwnProperty('event')) {
        return this.selectedAction === this.action.event && !this.isMobile
      } else return false
    }
  },
  methods: {
    addComma,
    handleClickAction (event, action) {
      if (!this.isMobile && action.hasOwnProperty('event')) {
        this.$emit('eventClick', action.event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-action {
  position: relative;
  line-height: 50px;
  align-items: center;
  transition: all .3s ease;
  font-weight: 500;
  color: #888888;
  padding: 0 10px;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f050;
    transition: all .3s ease;
  }

  &.active {
    color: #000000;
    background-color: #f0f0f0f2;

    &:after {
      content: ' ';
      height: 80%;
      width: 3px;
      background-color: #eea1a0;
      position: absolute;
      left: 0;
      top: 10%;
      bottom: 10%;
    }
  }

  .action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    width: 17px;
  }

  i {
    display: flex;
    align-items: center;
    margin: 0 15px;

    &.icon-chevron-left {
      font-size: 17px;
      font-weight: 700;
    }
  }

  .action-badge-box {
    display: flex;
    align-items: center;

    .action-badge {
      min-width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 0.5rem;
      text-align: center;
      padding: 0 5px;
      color: #FFFFFF;

      &.badge- {
        &primary {
          background: rgba(31, 116, 255, 1);
        }

        &success {
          background: rgba(70, 201, 58, 1);
        }

        &danger {
          background: rgba(255, 71, 87, 1);
        }

        &warning {
          background: rgba(255, 186, 0, 1);
        }

        &light {
          background: rgba(245, 245, 245, 1);
        }

        &dark {
          background: rgba(30, 30, 0, 1);
        }
      }
    }
  }
}
</style>
