<template>
  <div v-if="action.hasOwnProperty('permission') ? checkUserPermissions(action.permission) : true"
       :class="[{[`${action.type}-type-action`]: action.type}]">
    <profile-action-item v-if="!isMobile && action.hasOwnProperty('event')"
                         class="profile-action"
                         :action="action"
                         :selected-action="selectedAction"
                         @eventClick="handleClickAction($event, action)" />
    <a v-else-if="action.hasOwnProperty('routeType') && action.routeType === 'external-link'" :href="action.route" :target="action.target || ''">
      <profile-action-item class="profile-action"
                           :action="action"
                           :selected-action="selectedAction"
                           @eventClick="handleClickAction($event, action)" />
    </a>
    <router-link v-else :to="{name: action.name, params: action.params || {}, query: action.query || {}}">
      <profile-action-item class="profile-action"
                           :action="action"
                           :selected-action="selectedAction"
                           @eventClick="handleClickAction($event, action)" />
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {addComma, checkUserPermissions} from "../../assets/js/functions";
import CustomIcon from "../customIcon/customIcon.vue";
import ProfileActionItem from "./profileActionItem.vue";

export default {
  name: "profileAction",
  components: {ProfileActionItem, CustomIcon},
  props: {
    action: {
      type: Object,
      default: {},
      required: true
    },
    selectedAction: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    checkActionIsActive () {
      if (this.action.hasOwnProperty('event')) {
        return this.selectedAction === this.action.event
      } else return false
    }
  },
  methods: {
    checkUserPermissions,
    addComma,
    handleClickAction (event, action) {
      if (!this.isMobile && action.hasOwnProperty('event')) {
        this.$emit('eventClick', action.event)
      }
    }
  }
}
</script>

<style lang="scss">
.important-type-action {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  //width: fit-content !important;
  padding: 5px;
  width: 25%;
  max-width: 120px;

  a {
    width: 100%;
    height: 100%;
  }

  .profile-action {
    flex-direction: column;
    padding: 10px 5px;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background-color: #f0f0f070;

    &:hover {
      background-color: #f0f0f0f0;
    }

    &.active {
      &:after {
        content: ' ';
        height: 3px;
        width: 30px;
        background-color: #eea1a0;
        border-radius: 0.5rem;
        position: absolute;
        top: calc(100% - 4px);
        left: calc(50% - 15px);
        right: calc(50% - 15px);
      }
    }

    .action-icon {
      margin: 0;
    }

    i {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action-label {
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
      width: 100% !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
